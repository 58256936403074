



import { Component, Vue } from 'vue-property-decorator';
import Breadcrumb from '@/components/contents/breadcrumb/Breadcrumb.vue';

@Component({
  components: {
    Breadcrumb,
  },
})
export default class Sitemap extends Vue {
  private breadcrumb: BreadcrumbArr = [
    {
      title: 'HOME',
      link: '/',
    },
    {
      title: 'サイトマップ',
      link: '',
    },
  ];
  get aboutUrl(): string {
    return process.env.VUE_APP_PATH_ABOUT;
  }
  get aboutFlowUrl(): string {
    return process.env.VUE_APP_PATH_ABOUT_FLOW;
  }
  get aboutVoiceUrl(): string {
    return process.env.VUE_APP_PATH_ABOUT_VOICE;
  }
  get faqUrl(): string {
    return process.env.VUE_APP_PATH_FAQ;
  }
  get infoUrl(): string {
    return process.env.VUE_APP_PATH_INFO;
  }
  get accountCourseUrl(): string {
    return process.env.VUE_APP_PATH_ACCOUNT_COURSE;
  }
  get accountEditAccountUrl(): string {
    return process.env.VUE_APP_PATH_ACCOUNT_EDIT_ACCOUNT;
  }
  get accountCancelUrl(): string {
    return process.env.VUE_APP_PATH_ACCOUNT_CANCEL;
  }
  get courseUrl(): string {
    return process.env.VUE_APP_PATH_COURSE;
  }
  get signupUrl(): string {
    return process.env.VUE_APP_PATH_SIGNUP;
  }
  get signupMailmagazineUrl(): string {
    return process.env.VUE_APP_PATH_SIGNUP_MAILMAGAZINE;
  }
  get kiyakuUrl(): string {
    return process.env.VUE_APP_PATH_KIYAKU;
  }
  get privacypolicyUrl(): string {
    return process.env.VUE_APP_PATH_PRIVACY_POLICY;
  }
  get privacypolicyToriatsukaiUrl(): string {
    return process.env.VUE_APP_PATH_PRIVACY_POLICY_TORIATSUKAI;
  }
  get privacypolicyCookieUrl(): string {
    return process.env.VUE_APP_PATH_PRIVACY_POLICY_COOKIE;
  }
  get noticeUrl(): string {
    return process.env.VUE_APP_PATH_PRIVACY_NOTICE;
  }
  get noticeSuishoUrl(): string {
    return process.env.VUE_APP_PATH_PRIVACY_NOTICE_SUISHO;
  }
}
